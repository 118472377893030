// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./cinelog/movies_list.png");
var ___HTML_LOADER_IMPORT_1___ = require("./cinelog/movies_wishlist.png");
var ___HTML_LOADER_IMPORT_2___ = require("./cinelog/movies_view.png");
var ___HTML_LOADER_IMPORT_3___ = require("./cinelog/movies_edit.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<h1 id=\"captures-décran\">Captures d&#39;écran</h1> <br> <div style=\"display:flex;justify-content:space-around\"> <img alt=\"Liste des avis\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" width=\"200\"/> <img alt=\"Liste de souhaits\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" width=\"200\"/> <img alt=\"Avis\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" width=\"200\"/> <img alt=\"Édition d'un avis\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" width=\"200\"/> </div>";
// Exports
module.exports = code;